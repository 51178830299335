import { create } from "zustand";

export enum UserRole {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  OWNER = 'OWNER'
}

interface User {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  photo?: string;
  phone?: string;
  customer_id: string;
  created_at: Date;
}

interface LoginState {
  isLogged: boolean;
  user: User | null;
  setLogin: (logged: boolean) => void;
  setUser: (user: User | null) => void;
}

export const useLogin = create<LoginState>((set) => ({
  isLogged: false,
  user: null,
  setLogin: (logged) => set({ isLogged: logged }),
  setUser: (user) => set({ user }),
}));