import { createBrowserRouter, createHashRouter } from "react-router-dom";
import React, { Suspense, lazy, ReactNode } from "react";
import { Provider } from "react-redux";

// Importações internas
import store from "../redux/store.js";
import Page501 from "../container/pages/501/index";
import Dashboard from "../container/dashboard/index";
import PrivacyPolicy from "../container/pages/PrivacyPolicy/index";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import { useDisclosure } from "hooks/useDisclosure.js";

// Lazy components
const Projects = lazy(() => import("../container/pages/Projects/index"));
const Page404 = lazy(() => import("../container/pages/404/index"));
const Client = lazy(() => import("../container/pages/Client/index"));
const Login = lazy(() => import("../container/pages/Login/index"));
const SignIn = lazy(() => import("../container/pages/SignIn/index"));
const SuccessSignIn = lazy(() => import("../container/pages/CadastroSucesso/index"));
const ClientDetails = lazy(() => import("../container/pages/ClientDetails/index"));
const IntegrationPage = lazy(() => import("../container/pages/IntegrationPage/index"));
const ProjectEdit = lazy(() => import("../container/pages/ProjectEdit/index"));
const App = lazy(() => import("../App"));

// Função para encapsular o lazy loading e Suspense
const LazyLoad = (Component: React.FC): ReactNode => (
  <Suspense fallback={<div>Loading...</div>}>
    <Component />
  </Suspense>
);


// Router
const router = createHashRouter([
  {
    path: "/",
    element: <Provider store={store}>{LazyLoad(App)}</Provider>,
    children: [
      { path: "/", element: LazyLoad(Login) },
      { path: "/privacy-policy", element: LazyLoad(PrivacyPolicy) },
      { path: "/invite/:token", element: LazyLoad(SignIn) },
      { path: "/sign-in", element: LazyLoad(SignIn) },

      {
        path: "/",
        element: <ProtectedRoute />,
        children: [
          { path: "/projects", element: LazyLoad(Projects) },
          { path: "/client", element: LazyLoad(Client) },
          { path: "/client/details/:id", element: LazyLoad(ClientDetails) },
          { path: "/client/:id/integration", element: LazyLoad(IntegrationPage) },
          { path: "/projects/new", element: LazyLoad(ProjectEdit) },
          { path: "/projects/edit/:id", element: LazyLoad(ProjectEdit) },
          { path: "/501", element: LazyLoad(Page501) },
          { path: "/dashboard", element: LazyLoad(Dashboard) },
          { path: "/exit", element: LazyLoad(Login) },
          { path: "/success", element: LazyLoad(SuccessSignIn) },

        ],
      },
    ],
    errorElement: LazyLoad(Page404),
  },
]);

export default router;
